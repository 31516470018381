<script lang="ts" setup>
import ReminderCompact from '~/components/cards/ReminderCompact.vue';
import ReminderList from '~/components/lists/ReminderList.vue';

const { layoutState } = useLayout();
const todayStr = new Date().toISOString().split('T')[0]
</script>

<template>
  <Drawer v-model:visible="layoutState.rightMenuActive" position="right">
    <div class="h-full py-6 pr-0">
      <div>
        <h5 class="mt-8 mx-4 mb-2 text-color opacity-80">Today Reminders</h5>
        <ReminderList :date="todayStr" />
      </div>

      <hr />

      <div>
        <h5 class="mt-8 mx-4 mb-2 text-color opacity-80">Upcoming Reminders</h5>
        <ReminderList :date-gt="todayStr" />
      </div>
    </div>
  </Drawer>
</template>

<style lang="scss" scoped>
:deep(.p-checkbox) {
  width: 32px;
  height: 32px;

  .p-checkbox-box {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &.p-highlight {
    .p-checkbox-box {
      color: #515c66;
      background-color: transparent;
      border-color: transparent;
    }

    .p-checkbox-icon {
      color: var(--text-color);
    }

    &:hover {
      border-color: #d9dee6;
    }
  }
}
</style>
