<script lang="ts" setup>
import AppMenuItem from './AppMenuItem.vue';
import type { MenuItem } from "primevue/menuitem";

const { currentCompany } = useCompany()

const model = computed<MenuItem>(() => {
  if (!currentCompany.value) return []

  const menu = [
    {
      label: 'RhinoCalls',
      icon: 'pi pi-home',
      items: [
        {
          label: 'Dashboard',
          icon: 'pi pi-home',
          to: {
            name: 'companyId',
            params: { companyId: currentCompany.value?.id },
          },
        }
      ]
    },
    {
      label: 'Menu',
      items: [
        {
          label: 'Calendar',
          icon: 'pi pi-calendar',
          to: {
            name: 'companyId-crm-calendar',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Board',
          icon: 'pi pi-objects-column',
          to: {
            name: 'companyId-crm-board',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Leads',
          icon: 'pi pi-user',
          to: {
            name: 'companyId-crm-leads',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Phone Numbers',
          icon: 'pi pi-phone',
          to: {
            name: 'companyId-crm-phone-numbers',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Campaigns',
          icon: 'pi pi-compass',
          to:{
            name: 'companyId-crm-campaigns',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Calls',
          icon: 'pi pi-megaphone',
          to: {
            name: 'companyId-crm-calls',
            params: {companyId: currentCompany.value?.id},
          },
        },
        {
          label: 'Conversations',
          icon: 'pi pi-comments',
          to: {
            name: 'companyId-crm-conversations',
            params: {companyId: currentCompany.value?.id},
          },
        },
      ]
    }
  ]

  if (currentCompany.value?.id === 29 || location.host === 'localhost:3000') {
    menu[1].items.push(
      {
        label: 'Scrapper',
        icon: 'pi pi-sparkles',
        items: [
          {
            label: 'Customers',
            to: {
              name: 'companyId-crm-scrapper-customers',
              params: { companyId: currentCompany.value?.id },
            },
          },
          {
            label: 'Proxies',
            to: {
              name: 'companyId-crm-scrapper-proxies',
              params: { companyId: currentCompany.value?.id },
            },
          },
          {
            label: 'Angi',
            items: [
              {
                label: 'Credentials',
                to: {
                  name: 'companyId-crm-scrapper-angi-credentials',
                  params: { companyId: currentCompany.value?.id },
                },
              },
            ]
          },
          {
            label: 'Networx',
            items: [
              {
                label: 'Credentials',
                to: {
                  name: 'companyId-crm-scrapper-networx-credentials',
                  params: { companyId: currentCompany.value?.id },
                }
              }
            ]
          },
          {
            label: 'Dallas City Hall',
            items: [
              {
                label: 'Application types',
                to: {
                  name: 'companyId-crm-scrapper-dallas-city-hall-application-types',
                  params: { companyId: currentCompany.value?.id },
                }
              },
              {
                label: 'Applications',
                to: {
                  name: 'companyId-crm-scrapper-dallas-city-hall-applications',
                  params: { companyId: currentCompany.value?.id },
                }
              },
            ]
          },
        ]
      }
    )
  }

  menu.push(
    {
      label: 'AI',
      items: [
        {
          label: 'Prompts',
          icon: 'pi pi-comment',
          to: {
            name: 'companyId-ai-prompts',
            params: { companyId: currentCompany.value?.id },
          },
        },
        {
          label: 'Claude',
          items: [
            {
              label: 'Requests',
              to: {
                name: 'companyId-ai-claude-requests',
                params: { companyId: currentCompany.value?.id },
              },
            }
          ]
        },
      ]
    }
  )
  menu.push(
    {
      label: 'Account',
      items: [
        {
          label: 'Your account',
          icon: 'pi pi-user',
          to: { name: 'account' },
        },
        {
          label: 'Switch company',
          icon: 'pi pi-home',
          to: { name: 'index' },
        },
        {
          label: 'Change password',
          icon: 'pi pi-key',
          to: { name: 'account-password' },
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => logout(),
        },
      ]
    }
  )

  menu.push(
    {
      label: 'Setup',
      items: [
        {
          label: 'Configuration',
          icon: 'pi pi-cog',
          to: {
            name: 'companyId-configuration',
            params: { companyId: currentCompany.value?.id },
          },
        }
      ]
    }
  )

  return menu
})
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
