<script lang="ts" setup>
const { layoutConfig, isMobile, onMenuToggle, onRightPanelToggle, onConfigSidebarToggle } = useLayout();
const { account, logout } = useAuth()
const { currentCompany } = useCompany()
</script>

<template>
    <div class="layout-topbar" :class="{ 'border-bottom-none': layoutConfig.topbarTheme !== 'light' }">
        <div class="layout-topbar-start">
            <a class="layout-menu-button-mobile lg:!hidden" @click="onMenuToggle">
                <i class="pi pi-bars"></i>
            </a>
            <NuxtLink to="/">
                <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none">
                    <img id="app-logo" alt="RhinoCalls" :src="'/layout/images/logo-' + (layoutConfig.topbarTheme === 'light' ? 'rhinocalls' : 'rhinocalls-dark') + '.png'" />
                    <span class="font-sans font-semibold tracking-tight text-3xl">RhinoCalls</span>
                </div>
            </NuxtLink>
            <a v-if="account" class="lg:hidden" v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
              <Avatar class="cursor-pointer" :label="account.first_name[0]" size="large" shape="circle" />
            </a>
            <div v-if="account" class="layout-topbar-menu shadow px-4 hidden" style="top: 9rem">
                <ul class="list-none p-0 m-0">
                    <li class="mb-2">
                        <div class="flex gap-4 p-4 bg-primary-600" @click="navigateTo({name: 'account'})">
                            <Avatar class="cursor-pointer" :label="account.first_name[0]" size="large" shape="circle" />
                            <div class="flex flex-col cursor-pointer">
                                <span class="font-semibold text-white">{{ account.first_name }} {{ account.last_name }}</span>
                                <span v-if="currentCompany" class="text-xs font-normal text-white">{{ currentCompany.name }}</span>
                            </div>
                        </div>
                    </li>
                    <li class="py-4 px-6 flex gap-2 cursor-pointer">
                        <a>
                            <i class="pi pi-cog pi-star text-lg mr-4"></i>
                            <span class="font-semibold">Settings</span>
                        </a>
                    </li>
                    <li class="py-4 px-6 flex gap-2 cursor-pointer">
                        <a>
                            <i class="pi pi-file pi-lock text-lg mr-4"></i>
                            <span class="font-semibold">Terms of Usage</span>
                        </a>
                    </li>
                    <li class="py-4 px-6 flex gap-2 cursor-pointer">
                        <a>
                            <i class="pi pi-compass pi-image text-lg mr-4"></i>
                            <span class="font-semibold">Support</span>
                        </a>
                    </li>
                    <li class="py-4 px-6 flex gap-2 cursor-pointer">
                        <a @click="logout">
                            <i class="pi pi-power-off pi-image text-lg mr-4"></i>
                            <span class="font-semibold">Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="layout-topbar-end border-surface">
            <div class="layout-topbar-actions-start">
                <a v-if="layoutConfig.menuMode !== 'horizontal'" class="layout-menu-button-desktop !hidden lg:flex" @click="onMenuToggle">
                    <i class="pi pi-bars"></i>
                </a>
            </div>

            <div class="layout-topbar-actions-end">
                <ul class="layout-topbar-items">

                    <li class="lg:hidden">
                        <a v-styleclass="{ selector: '@next', enterFromClass: '!hidden', enterActiveClass: 'animate-scalein', leaveToClass: '!hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-search"></i>
                        </a>
                        <div class="layout-topbar-search !hidden !absolute origin-top bg-surface-0 dark:bg-surface-900 p-2 border-t border-surface shadow" style="top: 3.5rem">
                            <IconField class="w-full">
                                <InputIcon class="pi pi-search" />
                                <InputText placeholder="Search" class="!w-full" />
                            </IconField>
                        </div>
                    </li>
                    <li>
                        <a v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
                            <OverlayBadge value="2">
                                <i class="pi pi-bell"></i>
                            </OverlayBadge>
                        </a>
                        <div class="layout-topbar-menu shadow pt-0 hidden">
                            <ul class="list-none p-0 m-0">
                                <li class="flex justify-between items-center px-4 p-4 mb-4 cursor-pointer">
                                    <h6 class="m-0 font-semibold">Notifications</h6>
                                    <Badge value="3"></Badge>
                                </li>
                                <li>
                                    <a class="py-2 px-4 flex gap-2 cursor-pointer hover:text-primary">
                                        <div class="flex items-center justify-center border rounded-full border-transparent bg-blue-100 text-surface-500 dark:text-surface-300" style="height: 30px; width: 30px">
                                            <i class="pi pi-shopping-cart text-lg text-blue-500"></i>
                                        </div>
                                        <div>
                                            <h6 class="m-0 font-semibold">Order <span>#2254</span> is placed</h6>

                                            <span class="text-sm text-surface-700 dark:text-surface-100">Total Amount of <span class="font-semibold">$34.50</span></span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Button type="button" icon="pi pi-palette" rounded @click="onConfigSidebarToggle"></Button>
                    </li>
                    <li class="layout-topbar-profile">
                        <a v-if="!isMobile && account" v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }">
                            <div class="flex gap-2">
                                <Avatar class="cursor-pointer" :label="account.first_name[0]" size="large" shape="circle" />
                                <div class="flex flex-col">
                                    <span class="font-semibold">{{ account.first_name }} {{ account.last_name }}</span>
                                    <span v-if="currentCompany" class="text-sm">{{ currentCompany.name }}</span>
                                </div>
                            </div>
                        </a>
                        <div v-if="account" class="layout-topbar-menu p-0 shadow hidden">
                            <ul class="list-none p-0 m-0">
                                <li class="mb-2">
                                    <div class="flex gap-4 p-4 bg-primary-600" @click="navigateTo({name: 'account'})">
                                        <Avatar class="cursor-pointer" :label="account.first_name[0]" size="large" shape="circle" />
                                        <div class="flex flex-col cursor-pointer">
                                            <span class="font-semibold !text-white">{{ account.first_name }} {{ account.last_name }}</span>
                                            <span v-if="currentCompany" class="text-xs font-normal !text-white">{{ currentCompany.name }}</span>
                                        </div>
                                    </div>
                                </li>
                                <li class="py-4 px-6 flex gap-2 cursor-pointer">
                                  <NuxtLink :to="{ name: 'account' }">
                                    <i class="pi pi-cog pi-star text-lg"></i>
                                    <span class="font-semibold mx-4">Account</span>
                                  </NuxtLink>
                                </li>
                                <li class="py-4 px-6 flex gap-2 cursor-pointer">
                                  <NuxtLink :to="{ name: 'account-password' }">
                                    <i class="pi pi-key pi-lock text-lg"></i>
                                    <span class="font-semibold mx-4">Change password</span>
                                  </NuxtLink>
                                </li>
                                <li class="py-4 px-6 flex gap-2 cursor-pointer">
                                  <NuxtLink :to="{ name: 'index' }">
                                    <i class="pi pi-home pi-image text-lg"></i>
                                    <span class="font-semibold mx-4">Switch company</span>
                                  </NuxtLink>
                                </li>
                                <li class="py-4 px-6 flex gap-2 cursor-pointer">
                                    <a href="#" @click="logout">
                                        <i class="pi pi-power-off pi-image text-lg"></i>
                                        <span class="font-semibold mx-4">Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="layout-rightmenu-button">
                        <a v-if="currentCompany" href="#" @click="onRightPanelToggle">
                            <i class="pi pi-arrow-left"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
