<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';
import AppBreadcrumb from '~/components/layout/AppBreadcrumb.vue';
import AppConfig from '~/components/layout/AppConfig.vue';
import AppFooter from '~/components/layout/AppFooter.vue';
import AppRightMenu from '~/components/layout/AppRightMenu.vue';
import AppSidebar from '~/components/layout/AppSidebar.vue';
import AppTopbar from '~/components/layout/AppTopbar.vue';
import * as Sentry from "@sentry/vue";
import Dialer from "~/components/dialer/Dialer.vue";

const { account } = useAuth()
const { currentCompany } = useCompany()
const { layoutConfig, layoutState, watchSidebarActive, unbindOutsideClickListener } = useLayout()
const url = useRequestURL()

onMounted(() => {
    watchSidebarActive();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
  return [
    'layout-container',
    {
      'layout-light': !layoutConfig.value.darkTheme,
      'layout-dark': layoutConfig.value.darkTheme,
      'layout-static': layoutConfig.value.menuMode === 'static',
      'layout-horizontal': layoutConfig.value.menuMode === 'horizontal',
      'layout-overlay': layoutConfig.value.menuMode === 'overlay',
      'layout-overlay-active': layoutState.value.overlayMenuActive,
      'layout-mobile-active': layoutState.value.staticMenuMobileActive,
      'layout-static-inactive': layoutState.value.staticMenuDesktopInactive && layoutConfig.value.menuMode === 'static'
    },
    'layout-topbar-' + layoutConfig.value.topbarTheme,
    'layout-menu-' + layoutConfig.value.menuTheme
  ];
});

const siteName = computed<string>(() => {
  if (currentCompany.value) return currentCompany.value.name;

  if (url.hostname === 'crmig.org') return "CRMIG";
  return "RhinoCalls";
});

const setupSentry = () => {
  if (!account.value) return;

  Sentry.setUser({
    id: account.value.id,
    email: account.value.email,
  });
}

onMounted(async () => {
  setTimeout(function () {
    setupSentry();
  }, 5000);
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value;
  },
});
</script>

<template>
  <div :class="containerClass">
    <AppTopbar ref="topbarRef" />
    <AppRightMenu v-if="currentCompany" />
    <AppSidebar ref="sidebarRef" />

    <div class="layout-content-wrapper">
      <AppBreadcrumb />
      <div class="layout-content">
        <NuxtPage />

        <Dialer />
      </div>
      <AppFooter />
    </div>

    <AppConfig />

    <div v-if="layoutState.staticMenuMobileActive" class="layout-mask"></div>
  </div>
  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
</template>
